//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Switches',
  data () {
    return {
      colors: [
        'primary','secondary','warning','success','info','danger','light','dark'
      ],
      fields: [
        { key: 'size' },
        { key: 'example' },
        { key: 'size_prop', label: 'Size prop' }
      ],
      items: [
        {
          size: 'Large', 
          example: {  variant: '3d', color: 'primary', size: 'lg', checked: true }, 
          size_prop: 'Add following prop <code>size="lg"</code>'
        },
        {
          size: 'Normal', 
          example: {  variant: '3d', color: 'primary', size: '', checked: true }, 
          size_prop: '-'
        },
        {
          size: 'Small', 
          example: { variant: '3d', color: 'primary', size: 'sm', checked: true}, 
          size_prop: 'Add following prop <code>size="sm"</code>'
        }
      ],
      checker: true,
      radio: 'warning',
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      labelTxt: {
        labelOn: 'yes',
        labelOff: 'no'
      }
    }
  }
}
